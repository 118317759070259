import { AudioCollection } from "@/modules/audio/types"
import { store } from "@/store"

function getOptions(type, id) {
  switch (type) {
    case AudioCollection.Client:
      return { clientID: store.state.auth.clientID, audioID: id }
    case AudioCollection.Game:
      return {
        orgID: store.state.orgID,
        gameID: store.state.gameID,
        audioID: id
      }
    case AudioCollection.User:
      return {
        orgID: store.state.orgID,
        userID: store.state.auth.user?.id,
        audioID: id
      }
  }
}

export default {
  methods: {
    async $_AudioMixin_update(audio, type) {
      const service = await this.$services.get("audio")
      if (audio.id) {
        const options = getOptions(type, audio.id)
        return service.update(type, options, audio)
      } else {
        const options = getOptions(type)
        return service.create(type, options, audio)
      }
    },
    async $_AudioMixin_delete(id, type) {
      const service = await this.$services.get("audio")
      await service.delete(type, getOptions(type, id))
    }
  }
}
