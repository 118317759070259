var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-drawer",
    _vm._b(
      {
        attrs: {
          "show-sub-menu": !!_vm.audio,
          "menu-background": "#292932",
          "sub-menu-background": "#292932",
          "is-loading": _vm.state.working,
          width: 358,
          right: "",
          "offset-y": _vm.isMeeting ? 0 : 40,
        },
        on: { updateShowSubMenu: _vm.updateShowSubMenu },
        scopedSlots: _vm._u([
          {
            key: "menu-content",
            fn: function () {
              return [
                _c("AudioListPanel", {
                  on: { initAdd: _vm.initAdd, initEdit: _vm.initEdit },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "submenu-content",
            fn: function (ref) {
              var back = ref.back
              return [
                !!_vm.audio
                  ? _c("AudioSettingsPanel", {
                      key: _vm.audio && _vm.audio.id,
                      attrs: { value: _vm.audio },
                      on: { back: back },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.state.open,
          callback: function ($$v) {
            _vm.$set(_vm.state, "open", $$v)
          },
          expression: "state.open",
        },
      },
      "custom-drawer",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }