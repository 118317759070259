import { uploadMedia } from "../services/storage.service"

export interface Options {
  fileName: string
}
export default class Uploader {
  constructor(private _token: string) {}

  async upload(file: File, { fileName }: Options) {
    const url = await uploadMedia({
      fileName,
      blob: file,
      token: this._token,
      axiosConfig: {}
    })
    return url
  }
}
