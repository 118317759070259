<template>
  <v-hover class="audio-list-panel" :close-delay="500">
    <div
      class="audio-list-panel__content relative px-3"
      :style="
        isMeetingMode && !isAudioStop && isDIYGame && { 'padding-top': '35px' }
      "
    >
      <div class="audio-list-panel__controls mb-3">
        <h1 class="white--text mt-2" style="font-size: 22px">
          {{ isUserView ? "Music" : "Audio" }}
        </h1>
        <weve-text-field
          class="mb-3 mt-3"
          v-model="trackName"
          ref="searchField"
          label="Search"
          hide-label
          placeholder="Search audio..."
          @keydown.enter="onSubmit"
        />
        <v-layout flex align-center justify-center>
          <RtbToggleButton
            v-if="!isUserView"
            style="height: 32px; width: 232px"
            v-model="currentAudioType"
            :items="audioTypes"
            class="w-full"
          />
          <v-btn
            v-if="!isUserView"
            @click="uploadAudio"
            fab
            style="width: 32px; height: 32px"
          >
            <v-icon>add_circle</v-icon>
          </v-btn>
          <input
            v-show="false"
            ref="input"
            type="file"
            :accept="['.m4a', '.mp3', '.ogg', '.mpeg'].join(',')"
            class="rtb-file-uploader__input"
            @change="changeHandler"
            :file-name="getFileName"
          />
        </v-layout>
      </div>

      <RecycleScroller
        class="audio-list-panel__list"
        :items="audioToDisplay"
        :item-size="55"
        key-field="id"
        v-slot="{ item }"
      >
        <div
          class="audio-list-panel__item"
          @click="play(item)"
          :class="{ 'audio-list-panel__item--attached': isAttached(item) }"
        >
          <div
            class="audio-list-panel__play-btn"
            :class="{
              'audio-list-panel__play-btn--active': item.playing
            }"
          >
            <template v-if="currentAudioType !== 'sounds'">
              <template v-if="item.playing">
                <v-icon dark>
                  {{ audioTrack.status === "play" ? "pause" : "play_arrow" }}
                </v-icon>
              </template>
              <template v-else>
                {{ item.keypress }}
              </template>
            </template>
            <template v-else>
              <template v-if="item.playing">
                <v-icon dark>
                  {{ overlayTrack.status === "play" ? "pause" : "play_arrow" }}
                </v-icon>
              </template>
              <template v-else>
                {{ item.keypress }}
              </template>
            </template>
          </div>

          <div flex column class="audio-list-panel__item-name ml-3">
            {{ item.name }}
          </div>
          <div
            v-if="!isUserView"
            class="audio-list-panel__edit-btn"
            @click.stop="initEdit(item.theKey || item.id)"
          >
            <svg-icon size="small" name="settings-outlined" />
            <svg-icon
              style="height: 12px"
              name="chevron-right-regular"
              size="small"
            />
          </div>
        </div>
      </RecycleScroller>
    </div>
  </v-hover>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters, mapState } from "vuex"
import { v4 } from "uuid"
import { sortBy } from "lodash"
import { RecycleScroller } from "vue-virtual-scroller"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

import {
  KEY_J,
  KEY_SPACE,
  KEY_RETURN,
  KEY_0,
  KEY_1,
  KEY_2,
  KEY_3,
  KEY_4,
  KEY_5,
  KEY_6,
  KEY_7,
  KEY_8,
  KEY_9,
  KEY_BACK_QUOTE
} from "keycode-js"

import { RtbControlButton, RtbToggleButton } from "@/components/ui"

import { WeveTextField } from "@weve/ui"
import { AudioCollection } from "@/modules/audio/types"

import rules from "@/modules/audio/rules"
import Uploader from "@/helpers/uploader"

import useTheaterVotingSlides from "@/components/GroupTeams/Common/GameModes/TheaterVoting/useTheaterVotingSlides.ts"
import audioDrawerState from "../../drawers/audio"
import Role from "@shared/enums/Role"
import Mode from "@shared/enums/Mode"
import { db } from "@/firebase"

const AudioCollections = {
  user: AudioCollection.User,
  music: AudioCollection.Game
}

const AudioType = {
  Sfx: "sounds",
  User: "user",
  Music: "music"
}

export default Vue.extend({
  name: "AudioListPanel",
  components: {
    RecycleScroller,
    WeveTextField,
    RtbControlButton,
    RtbToggleButton
  },
  setup() {
    const { currentSlide } = useTheaterVotingSlides()
    return { currentSlide }
  },
  data() {
    return {
      audioDrawerState,
      currentAudioType: AudioType.User,
      trackName: undefined,
      audios: {
        [AudioCollection.User]: [],
        [AudioCollection.Game]: []
      },
      rules,
      uploader: new Uploader(this.$store.state.auth.token),
      source: null,
      file: null,
      local: {
        name: ""
      }
    }
  },
  computed: {
    ...mapState(["orgID", "gameID"]),
    ...mapGetters("auth", ["user", "isModerator"]),
    ...mapGetters("soundeffect", ["getAllAudios", "isAudioStop"]),
    ...mapGetters(["game", "getCurrentMode", "getCurrentMission", "isDIYGame"]),
    isMeetingMode() {
      return this.getCurrentMode === Mode.Meeting
    },
    isVas() {
      return this.game?.importedFromBreadcrumb
    },
    isVoting() {
      return this.getCurrentMode === Mode.Voting
    },
    isTheaterVoting() {
      return this.isVoting && this.getCurrentMission?.theaterVoting
    },
    isUserView() {
      return (
        this.$store.getters["auth/user"]?.role === Role.PLAYER &&
        !this.$store.getters["auth/isModerator"]
      )
    },
    audioTypes() {
      return [
        !this.isModerator && {
          name: "user",
          value: AudioType.User
        },
        {
          name: "sfx",
          value: AudioType.Sfx
        },
        {
          name: "music",
          value: AudioType.Music
        }
      ].filter(Boolean)
    },
    audioTrack() {
      return this.$store.state.game?.audioTrack
    },
    overlayTrack() {
      return this.$store.state.game?.overlayTrack
    },
    userAudios() {
      return sortBy(
        this.audios[AudioCollection.User].filter(sound => !sound.overlay),
        "name"
      )
    },
    gameAudios() {
      return sortBy(
        this.audios[AudioCollection.Game].filter(sound => !sound.overlay),
        "name"
      )
    },
    overlayAudios() {
      const audios = this.audios
      return sortBy(
        [
          ...audios[AudioCollection.User]
            .filter(sound => sound.overlay)
            .map(sound => ({ ...sound, collection: AudioCollection.User })),
          ...audios[AudioCollection.Game]
            .filter(sound => sound.overlay)
            .map(sound => ({ ...sound, collection: AudioCollection.Game }))
        ],
        "name"
      )
    },
    allAudios() {
      return {
        music: this.gameAudios,
        sounds: this.overlayAudios,
        user: this.userAudios
      }
    },
    audioToDisplay() {
      const overlayTrackSource = this.overlayTrack?.source
      const trackSource = this.audioTrack?.source

      return this.filteredAudio.map(audio => ({
        ...audio,
        keypress: String(audio.keypress || "-").toUpperCase(),
        playing: audio.overlay
          ? audio.source === overlayTrackSource
          : audio.source === trackSource
      }))
    },
    filteredAudio() {
      const string = this.trackName
      const audios = this.allAudios[this.currentAudioType]
      if (!string) return audios
      const normalized = String(string).toLowerCase().trim()
      return audios.filter(audio =>
        String(audio?.name).toLowerCase().trim().includes(normalized)
      )
    },
    isOpen() {
      return audioDrawerState.open
    }
  },
  async created() {
    if (this.isModerator || this.isUserView)
      this.currentAudioType = AudioType.Music

    const service = await this.$services.get("audio")

    const { orgID, gameID, user } = this

    this.subscriptions = [
      service.subscribe(
        AudioCollection.User,
        { orgID, userID: user?.id },
        audios => {
          this.audios[AudioCollection.User] = audios
        }
      ),
      service.subscribe(AudioCollection.Game, { orgID, gameID }, audios => {
        this.audios[AudioCollection.Game] = audios
      })
    ]

    window.addEventListener("keyup", this.onKeyUp)
  },
  beforeDestroy() {
    this.subscriptions?.forEach(unsubscribe => unsubscribe())
    window.removeEventListener("keyup", this.onKeyUp)
    audioDrawerState.working = false
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) {
          this.$refs.searchField.$refs.input.focus()
        } else {
          // this.trackName = ""
        }
      }
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateGameAudioTrack"]),
    onSubmit() {
      const audio = this.filteredAudio[0]
      if (audio && this.trackName !== "") this.play(audio)
    },
    changeHandler({ target }) {
      if (
        target !== null &&
        target instanceof HTMLInputElement &&
        target.files?.length
      ) {
        this.file = target.files[0]
        this.$refs.input.value = ""
        this.uploadFile()
      }
    },
    async uploadFile() {
      audioDrawerState.working = true

      try {
        this.local.name = ""
        const source = await this.uploader.upload(this.file, {
          fileName: this.getFileName(this.file)
        })
        if (this.file !== null && this.local?.name === "") {
          this.local.name = this.file.name.split(".")[0]
          const audioType = this.currentAudioType
          this.$emit("initAdd", {
            name: this.local.name,
            source,
            // wtf is this?
            collection: audioType === AudioType.User ? "user" : "game",
            overlay: audioType === AudioType.Sfx
          })
        }
      } catch (e) {
        console.log(e)
        audioDrawerState.working = false
      }
    },
    getFileName(file) {
      return `audio/${this.clientID}/${v4()}${file.name}`
    },
    uploadAudio() {
      this.$refs.input.click()
    },
    play(obj = {}) {
      const { id, source, volume, overlay, name } = obj
      let status = "play"
      if (
        (source === this.audioTrack?.source &&
          this.audioTrack?.status === "play") ||
        (source === this.overlayTrack?.source &&
          this.overlayTrack?.status === "play")
      ) {
        status = "pause"
      }

      this.updateGameAudioTrack({
        name: name || "",
        source,
        key: id,
        volume,
        overlay,
        status,
        playing: false
      })

      if (this.isVas) {
        this.attachAudiotoPlay(obj)
      }

      this.trackName = ""
    },
    onKeyUp({ keyCode, shiftKey, target }) {
      if (keyCode === KEY_J && shiftKey) return

      if (
        (["text", "textarea", "search", "number"].includes(target?.type) &&
          ![null, undefined, ""].includes(target?.value)) ||
        keyCode === KEY_SPACE ||
        keyCode === KEY_RETURN
      ) {
        return
      }

      if (
        (this.$route.name !== "game" && this.$route.name !== "pickteams") ||
        this.$store.getters["Games/isAddingSecOnGameCountDown"]
      ) {
        return
      }

      if (
        this.isTheaterVoting &&
        [
          KEY_0,
          KEY_1,
          KEY_2,
          KEY_3,
          KEY_4,
          KEY_5,
          KEY_6,
          KEY_7,
          KEY_8,
          KEY_9,
          KEY_BACK_QUOTE
        ].includes(keyCode)
      ) {
        return
      }

      this.playOnKeyUp(keyCode)
    },
    playOnKeyUp(keyCode) {
      const keypress = String.fromCharCode(keyCode)

      const serialize = string => String(string).toLowerCase().trim()

      const audios = this.audios

      const isKeypressAudio = audio =>
        serialize(audio?.keypress) &&
        serialize(audio?.keypress) === serialize(keypress)

      const found =
        audios[AudioCollection.User].find(isKeypressAudio) ||
        audios[AudioCollection.Game].find(isKeypressAudio)

      if (found) this.play(found)
    },
    getAudioByID(audioID) {
      return this.allAudios[this.currentAudioType].find(
        audio => audio.key === audioID || audio.id === audioID
      )
    },
    initEdit(id) {
      const audio = this.getAudioByID(id)
      this.$emit("initEdit", {
        ...audio,
        collection: audio.collection
          ? audio.collection
          : AudioCollections[this.currentAudioType]
      })
    },
    async attachAudiotoPlay(media) {
      if (this.currentAudioType === "sounds" || media.overlay) {
        return
      }

      const path = `org/${this.orgID}/game/${this.gameID}/play/${this.currentSlide.id}`

      await db
        .auxiliary()
        .ref(path)
        .update({ bgSound: this.isAttached(media) ? null : media })
    },
    isAttached(media) {
      const slideMediaId = this.currentSlide?.bgSound?.id
      return media.id === slideMediaId
    }
  }
})
</script>

<style lang="scss">
.audio-list-panel {
  display: flex;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__list {
    flex: 1;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    padding: 5px 10px;

    &:hover {
      background: #393b42;
      .audio-list-panel__edit-btn {
        visibility: visible;
      }
    }

    &--attached {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &__play-btn {
    width: 40px;
    height: 40px;
    background: #586475;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover,
    &--active {
      background-color: #8680ff !important;
    }
  }

  &__edit-btn {
    visibility: hidden;
  }

  &__item-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
