var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-hover",
    { staticClass: "audio-list-panel", attrs: { "close-delay": 500 } },
    [
      _c(
        "div",
        {
          staticClass: "audio-list-panel__content relative px-3",
          style: _vm.isMeetingMode &&
            !_vm.isAudioStop &&
            _vm.isDIYGame && { "padding-top": "35px" },
        },
        [
          _c(
            "div",
            { staticClass: "audio-list-panel__controls mb-3" },
            [
              _c(
                "h1",
                {
                  staticClass: "white--text mt-2",
                  staticStyle: { "font-size": "22px" },
                },
                [_vm._v(" " + _vm._s(_vm.isUserView ? "Music" : "Audio") + " ")]
              ),
              _c("weve-text-field", {
                ref: "searchField",
                staticClass: "mb-3 mt-3",
                attrs: {
                  label: "Search",
                  "hide-label": "",
                  placeholder: "Search audio...",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSubmit.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.trackName,
                  callback: function ($$v) {
                    _vm.trackName = $$v
                  },
                  expression: "trackName",
                },
              }),
              _c(
                "v-layout",
                {
                  attrs: { flex: "", "align-center": "", "justify-center": "" },
                },
                [
                  !_vm.isUserView
                    ? _c("RtbToggleButton", {
                        staticClass: "w-full",
                        staticStyle: { height: "32px", width: "232px" },
                        attrs: { items: _vm.audioTypes },
                        model: {
                          value: _vm.currentAudioType,
                          callback: function ($$v) {
                            _vm.currentAudioType = $$v
                          },
                          expression: "currentAudioType",
                        },
                      })
                    : _vm._e(),
                  !_vm.isUserView
                    ? _c(
                        "v-btn",
                        {
                          staticStyle: { width: "32px", height: "32px" },
                          attrs: { fab: "" },
                          on: { click: _vm.uploadAudio },
                        },
                        [_c("v-icon", [_vm._v("add_circle")])],
                        1
                      )
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    ref: "input",
                    staticClass: "rtb-file-uploader__input",
                    attrs: {
                      type: "file",
                      accept: [".m4a", ".mp3", ".ogg", ".mpeg"].join(","),
                      "file-name": _vm.getFileName,
                    },
                    on: { change: _vm.changeHandler },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("RecycleScroller", {
            staticClass: "audio-list-panel__list",
            attrs: {
              items: _vm.audioToDisplay,
              "item-size": 55,
              "key-field": "id",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "audio-list-panel__item",
                        class: {
                          "audio-list-panel__item--attached":
                            _vm.isAttached(item),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.play(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "audio-list-panel__play-btn",
                            class: {
                              "audio-list-panel__play-btn--active":
                                item.playing,
                            },
                          },
                          [
                            _vm.currentAudioType !== "sounds"
                              ? [
                                  item.playing
                                    ? [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.audioTrack.status === "play"
                                                  ? "pause"
                                                  : "play_arrow"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _vm._v(
                                          " " + _vm._s(item.keypress) + " "
                                        ),
                                      ],
                                ]
                              : [
                                  item.playing
                                    ? [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.overlayTrack.status ===
                                                  "play"
                                                  ? "pause"
                                                  : "play_arrow"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _vm._v(
                                          " " + _vm._s(item.keypress) + " "
                                        ),
                                      ],
                                ],
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "audio-list-panel__item-name ml-3",
                            attrs: { flex: "", column: "" },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        ),
                        !_vm.isUserView
                          ? _c(
                              "div",
                              {
                                staticClass: "audio-list-panel__edit-btn",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.initEdit(item.theKey || item.id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    size: "small",
                                    name: "settings-outlined",
                                  },
                                }),
                                _c("svg-icon", {
                                  staticStyle: { height: "12px" },
                                  attrs: {
                                    name: "chevron-right-regular",
                                    size: "small",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }