<template>
  <custom-drawer
    v-model="state.open"
    v-bind="$attrs"
    :show-sub-menu="!!audio"
    menu-background="#292932"
    sub-menu-background="#292932"
    @updateShowSubMenu="updateShowSubMenu"
    :is-loading="state.working"
    :width="358"
    right
    :offset-y="isMeeting ? 0 : 40"
  >
    <template #menu-content>
      <AudioListPanel @initAdd="initAdd" @initEdit="initEdit" />
    </template>
    <template #submenu-content="{ back }">
      <AudioSettingsPanel
        v-if="!!audio"
        :key="audio && audio.id"
        @back="back"
        :value="audio"
      />
    </template>
  </custom-drawer>
</template>
<script>
import { mapGetters } from "vuex"

import CustomDrawer from "@/components/CustomDrawer"
import AudioListPanel from "@/components/GroupTeams/Common/AudioListPanel"
import state from "./index"
import AudioMixin from "@/mixins/AudioMixin"
import Mode from "@shared/enums/Mode"

export default {
  name: "AudioDrawer",
  components: {
    CustomDrawer,
    AudioListPanel,
    AudioSettingsPanel: () =>
      import("@/components/GroupTeams/AudioSettingsPanel")
  },
  mixins: [AudioMixin],
  computed: {
    ...mapGetters({ mode: "getCurrentMode" }),
    isMeeting() {
      this.mode === Mode.Meeting
    }
  },
  data() {
    return {
      audio: null,
      state
    }
  },
  methods: {
    updateShowSubMenu(value) {
      if (!value) this.audio = null
    },
    async initAdd(_audio) {
      try {
        const audio = {
          ..._audio
        }

        const response = await this.$_AudioMixin_update(
          audio,
          _audio.collection
        )

        const id = response.key

        this.audio = {
          ...audio,
          id
        }
      } catch (e) {
        console.log(e)
      } finally {
        state.working = false
      }
    },
    initEdit(audio) {
      this.audio = {
        ...audio
      }
    }
  }
}
</script>
